import React from "react";
import Message from "../components/messageTemplate";

const Thanks = () => (
  <Message
    title="Thanks"
    headline="Thank You"
    message="Your message has been sent and we'll be in touch shortly!"
  />
);

export default Thanks;
